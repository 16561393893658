import UserStorageActions from '../actions/UserStorageActions.jsx'

var STORAGE_KEY = '@USERSTORAGE';

class UserStorage {
  constructor() {
    this.userDefaults = {};
    this.bindListeners({
      setObjectForKey: UserStorageActions.SET_OBJECT_FOR_KEY,
      resetKey: UserStorageActions.RESET_KEY,
      initStorage: UserStorageActions.INIT_STORAGE,
      synchronize: UserStorageActions.SYNCHRONIZE,
    });

    this.exportPublicMethods({
      getUserDefaults: this.getUserDefaults,
    });
  }

  getUserDefaults() {
    return this.state.userDefaults;
  }

  setObjectForKey(ko) {
    this.userDefaults[ko.key] = ko.obj;
  }

  resetKey(ko) {
    this.userDefaults[ko.key] = undefined;
  }



  initStorage() {
    if (window.localStorage.userDefaults != null) {
      this.userDefaults = JSON.parse(window.localStorage.userDefaults);
    } else {
      this.userDefaults = {};
    }
  }

  synchronize() {
    window.localStorage.userDefaults = JSON.stringify(this.userDefaults);
  }
}

module.exports = CK.Alt.createStore(UserStorage, 'UserStorage');