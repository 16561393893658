import React from 'react'
import ReactDOM from 'react-dom'
import IndexPage from './modules/vc/pages/IndexPage.jsx'
import UserStorageActions from './modules/actions/UserStorageActions.jsx'
import UserStorage from './modules/stores/UserStorage.jsx'

export default class AppDelegate extends React.Component{

  constructor(props) {
    super(props)
    this.state = {
      loaded: false
    }
  }

  componentDidMount() {
    UserStorageActions.initStorage.defer();
  }

  render() {
    return (
        <CK.Window ref="core" className="core_window">
          <IndexPage />
        </CK.Window>
    );
  }
}