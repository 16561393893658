import React from 'react'

var FontAwesome = require('react-fontawesome');
var Scroll  = require('react-scroll');

var Link    = Scroll.Link;
var Element = Scroll.Element;
var Events  = Scroll.Events;
var scroll  = Scroll.animateScroll;

class Nav extends React.Component{

  constructor(props) {
    super(props)
    this.state = {
      isHovered : false
    }
  }

  componentDidMount() {

  }

  setHovered(bool) {
    this.setState({isHovered: bool});
  }

  render() {
    return (
      <div style={!this.state.isHovered ? styles.nav : styles["nav:hover"]}
           onMouseEnter={() => {this.setHovered(true);}}
           onMouseLeave={() => {this.setHovered(false);}}>
        <Link style={this.props.style} to={this.props.link} spy={true} smooth={true} duration={500}>{this.props.children}</Link>
        <div className="anim-fade" style={{"opacity" : this.state.isHovered ? "1" : "0.01"}}>
          <div style={{height: `2px`, marginTop: `4px`, width: `100%`, position: `relative`, backgroundColor: `#FDA629`}} >&nbsp;</div>
        </div>
      </div>
    );
  }
}


export default class AppHeader extends React.Component{

  constructor(props) {
    super(props)
    this.state = {
      parallax_y : 0,
      containerWidth: 0,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this));

    this.setState({
      containerWidth: this.getContainerWidth()
    });

    window.addEventListener('resize', () => {
      this.setState({
        containerWidth: this.getContainerWidth()
      });
    }, false);
  }

  getContainerWidth() {
    return document.body.clientWidth;
  }



  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll.bind(this));
  }



  handleScroll(e) {
    let scrollTop = $(document).scrollTop();
    let itemTranslate = scrollTop / 2;

    this.setState({
      parallax_y: itemTranslate
    });
  }


  renderMenuItem(icon, text) {
    if (this.state.containerWidth < 720) {
      return (
          <div style={{"marginLeft" : "5px", "marginRight" : "5px", top: "3px", textAlign:`center`, position: `relative`}}><div style={{margin: `6px`}}><FontAwesome name={icon} size="2x" /></div></div>
      )
    } else {
      return (
          <div style={{"marginLeft" : "25px", marginRight: "25px", textAlign:`center`}}><div style={{margin: `6px`}}><FontAwesome name={icon} /></div>{text}</div>
      )
    }
  }


  renderBlack() {
    return (
        <CK.View className={ CK.Classes(this.props.className, "app_header") }
                 style={[this.props.style]}>

          <div>
            <img style={styles.logo} src={"./assets/images/logo_bw.png"}></img>
          </div>

          <div>
            <Nav link="whatwedo" style={{color: "white", textDecoration : "none"}}>{this.renderMenuItem('cogs', 'WHAT WE DO')}</Nav>
            <Nav link="portfolio" style={{color: "white", textDecoration : "none"}}>{this.renderMenuItem('paper-plane', 'PORTFOLIO')}</Nav>
            <Nav link="contactus" style={{color: "white", textDecoration : "none"}}>{this.renderMenuItem('pencil', 'CONTACT US')}</Nav>
          </div>

        </CK.View>
    )
  }

  renderWhite() {
    return (
        <CK.View className={ CK.Classes(this.props.className, "app_header", "background_white") }
                 style={{opacity: this.state.parallax_y > 50 ? 0.96 : 0}}>

          <div>
            <img style={styles.logo} src={"./assets/images/logo_wb.png"}></img>
          </div>

          <div>
            <Nav link="jumbotron" style={{color: "#555555", textDecoration : "none"}}>{this.renderMenuItem('home', 'HOME')}</Nav>
            <Nav link="whatwedo" style={{color: "#555555", textDecoration : "none"}}>{this.renderMenuItem('cogs', 'WHAT WE DO')}</Nav>
            <Nav link="portfolio" style={{color: "#555555", textDecoration : "none"}}>{this.renderMenuItem('paper-plane', 'PORTFOLIO')}</Nav>
            <Nav link="contactus" style={{color: "#555555", textDecoration : "none"}}>{this.renderMenuItem('pencil', 'CONTACT US')}</Nav>
          </div>

        </CK.View>
    )
  }


  render() {
    if (this.props.theme === "WHITE") {
      return this.renderWhite()
    } else if (this.props.theme === "BLACK") {
      return this.renderBlack()
    }
    return nil
  }
}

var styles = {
  "logo" : {
    "width" : "120px"
  },
  "nav" : {
    "font-family" : "sans-serif",
    "display" : "inline-block",
    "textAlign" : "center",
  },
  "nav:hover" : {
    "font-family" : "sans-serif",
    "display" : "inline-block",
    "textAlign" : "center",
    "cursor": "pointer",
  }
};