import React from 'react'
import ReactDOM from 'react-dom'
import Slider from 'react-slick'
import Parallax from 'react-parallax'
import Waypoint from 'react-waypoint';
import AutoResponsive from 'autoresponsive-react'
import AppHeader from '../components/AppHeader.jsx'
import OverlayBox from '../components/OverlayBox.jsx'
import {Motion, spring} from 'react-motion';

export default class WhatWeDo extends React.Component{

  constructor(props) {
    super(props)
    this.state = {
      prefix_factor: 0,
      suffix_factor: 0,
      subcaption_factor: 0,
      slider_factor: 0,
    }

    this.data = [
      {caption: 'Mobile App Design', image: './assets/images/c_ic_app.png', desc: (
          <ul>
            <li>50+ iOS &amp; Android Apps made for various clients</li>
            <li>Cross-platform / native apps solutions</li>
            <li>Over 10 years' apps development</li>
          </ul>
      )},
      {caption: 'Cloud Backend', image: './assets/images/c_ic_backend.png', desc: (
          <ul>
            <li>10+ Dedicated systems built for various clients</li>
            <li>Scalable backend solutions</li>
          </ul>
      )},
      {caption: 'IoT Solutions', image: './assets/images/c_ic_qa.png', desc: (
          <ul>
            <li>5+ Active use cases of various clients</li>
            <li>Scalable IoT solutions</li>
            <li>Hardware design</li>
            <li>Firmware design</li>
          </ul>
      )},
      {caption: 'Mobile Games', image: './assets/images/c_ic_games.png', desc: (
          <ul>
            <li>15+ Games made with ❤️</li>
            <li>2D / 3D games ready</li>
            <li>Supports AR / VR</li>
          </ul>
      )},
    ];


    this.carouselSettings = {
      arrows: false,
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4, slidesToScroll: 4,
      responsive: [
        { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 2 } },
        { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 3 } },
      ],
    };
  }

  componentDidMount() {
    setTimeout(() => {
      setTimeout(() => {this.setState({prefix_factor: 1});}, 200);
      setTimeout(() => {this.setState({suffix_factor: 1});}, 400);
      setTimeout(() => {this.setState({subcaption_factor: 1});}, 600);
      setTimeout(() => {this.setState({slider_factor: 1});}, 800);
    }, 2000)

    this.setState({
      containerWidth: this.getContainerWidth()
    });

    window.addEventListener('resize', () => {
      this.setState({
        containerWidth: this.getContainerWidth()
      });
    }, false);
  }

  getContainerWidth() {
    return document.body.clientWidth;
  }





  fadeIn(factor) {
    return {
      position: `relative`,
      opacity: `${0 + (1 * factor)}`,
    }
  }

  fadeInFromLeft(factor) {
    return {
      position: `relative`,
      left: `${-50 + (50 * factor)}px`,
      opacity: `${0 + (1 * factor)}`,
    }
  }

  fadeInFromTop(factor) {
    return {
      position: `relative`,
      top: `${-50 + (50 * factor)}px`,
      opacity: `${0 + (1 * factor)}`,
    }
  }


  render() {

    var items = this.data;
    var settings = this.carouselSettings;



    var content = null;
    if (this.state.containerWidth < 480) {
      content = (
          <Motion defaultStyle={{factor: 0}} style={{factor: spring(this.state.slider_factor) }}>
            {({factor}) => (
                <CK.View>
                  {
                    items.map((item, i) => {
                      return (
                          <CK.View className="list"><img src={item.image}/><span>{item.caption}<div className="item_desc">{item.desc}</div></span></CK.View>
                      )
                    })
                  }
                </CK.View>
            )}
          </Motion>
      )

    } else {
      content = (
          <Motion defaultStyle={{factor: 0}} style={{factor: spring(this.state.slider_factor) }}>
            {({factor}) => (
                <div style={this.fadeInFromTop(factor)}>
                  <Slider {...settings}>
                    {
                      items.map((item, i) => {
                        return (
                            <CK.View className="item"><img src={item.image}/><br/>{item.caption}<div className="item_desc">{item.desc}</div></CK.View>
                        )
                      })
                    }
                  </Slider>
                </div>
            )}
          </Motion>
      )
    }

    return (
        <CK.View className="section whatwedo" ref="container">
          <CK.View style={{backgroundColor: `#FFFFFF`}} className="skew_background_back" />
          <CK.View style={{backgroundColor: `#FFA700`}} className="skew_background_front" />
          <CK.View className="skew_content">
            <CK.View className="caption">
              <Motion defaultStyle={{factor: 0}} style={{factor: spring(this.state.prefix_factor, [600, 20]) }}>
                {({factor}) => (
                    <span style={this.fadeInFromLeft(factor)} className="prefix">What </span>
                )}
              </Motion>
              <Motion defaultStyle={{factor: 0}} style={{factor: spring(this.state.suffix_factor, [600, 20]) }}>
                {({factor}) => (
                    <span style={this.fadeInFromTop(factor)} className="suffix">We Do</span>
                )}
              </Motion>
            </CK.View>
            <Motion defaultStyle={{factor: 0}} style={{factor: spring(this.state.subcaption_factor, [600, 20]) }}>
              {({factor}) => (
                  <CK.View style={this.fadeInFromTop(factor)} className="subcaption">All-round, Brilliant, Customizing, Daringly Innovative and Integrative, we are your best business partners providing ultimate business solution.</CK.View>
              )}
            </Motion>

            {content}

          </CK.View>
        </CK.View>
    )

  }
}




