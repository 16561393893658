import React from 'react'
import ReactDOM from 'react-dom'
import Slider from 'react-slick'
import Parallax from 'react-parallax'
import Waypoint from 'react-waypoint';
import AutoResponsive from 'autoresponsive-react'
import AppHeader from '../components/AppHeader.jsx'
import OverlayBox from '../components/OverlayBox.jsx'
import {Motion, spring} from 'react-motion';

var Scroll  = require('react-scroll');

var Link    = Scroll.Link;
var Element = Scroll.Element;
var Events  = Scroll.Events;
var scroll  = Scroll.animateScroll;

export default class Jumbotron extends React.Component{

  constructor(props) {
    super(props)
    this.state = {
      overlay_factor: 0,
      slogan_factor: 0,
      subslogan_factor: 0,
      roundbutton_factor: 0,
      iphone_factor: 0,
      macbook_factor: 0,
      parallax_y: 0,
      gears_factor: 0,
    }
  }

  componentDidMount() {
    setTimeout(() => {this.setState({overlay_factor: 1});}, 100);
    setTimeout(() => {this.setState({slogan_factor: 1});}, 600);
    setTimeout(() => {this.setState({subslogan_factor: 1});}, 900);
    setTimeout(() => {this.setState({roundbutton_factor: 1});}, 1200);
    setTimeout(() => {this.setState({iphone_factor: 1});}, 1800);
    setTimeout(() => {this.setState({macbook_factor: 1});}, 2100);
    setTimeout(() => {this.setState({gears_factor: 1});}, 2500);

    //window.addEventListener('scroll', this.handleScroll.bind(this));
  }


  componentWillUnmount() {
    //window.removeEventListener('scroll', this.handleScroll.bind(this));
  }


  handleScroll(e) {
    let scrollTop = e.target.documentElement.scrollTop;
    let itemTranslate = scrollTop / 2;

    this.setState({
      parallax_y: itemTranslate
    });
  }


  fadeIn(factor) {
    return {
      opacity: `${0 + (1 * factor)}`,
    }
  }

  fadeInFromTop(factor) {
    return {
      position: `relative`,
      top: `${-50 + (50 * factor)}px`,
      opacity: `${0 + (1 * factor)}`,
    }
  }


  fadeInFromBottom(factor, bottomBefore, bottomAfter, style) {
    style.position = `relative`;
    style.bottom = `${-bottomBefore + (bottomAfter * factor)}px`;
    style.opacity = `${0 + (1 * factor)}`;
    return style;
  }

  fadeInFromRight(factor, rightBefore, rightAfter, style) {
    style.position = `relative`;
    style.right = `${-rightBefore + (rightAfter * factor)}px`;
    style.opacity = `${0 + (1 * factor)}`;
    return style;
  }

  render() {
    return (
        <CK.View className="jumbotron_container" style={{backgroundImage: 'url(./assets/images/blue_bg_tile_3.png)'}}>
          <Motion defaultStyle={{factor: 0}} style={{factor: spring(this.state.overlay_factor, [40, 12]) }}>
            {({factor}) => (
                <CK.View className="jumbotron">
                  <CK.View className="mask"/>
                  <CK.View className="mask_inner" style={{zIndex: 4, position: `absolute`, height: `100%`, top: ((this.state.parallax_y *3) / 2) + `px`}}>
                    <CK.View style={this.fadeIn(factor)} className="overlay">
                      <Motion defaultStyle={{factor: 0}} style={{factor: spring(this.state.slogan_factor) }}>
                        {({factor}) => (
                            <CK.View style={this.fadeInFromTop(factor)} className="slogan">YOUR <br/>SOLUTIONS <br/>ARE READY.</CK.View>
                        )}
                      </Motion>
                      <Motion defaultStyle={{factor: 0}} style={{factor: spring(this.state.subslogan_factor) }}>
                        {({factor}) => (
                            <CK.View style={this.fadeInFromTop(factor)} className="subslogan">WEBSITES · DB · API · MOBILE · UI · UX · SUPPORT</CK.View>
                        )}
                      </Motion>
                      {
                          false ? null : (
                              <Motion defaultStyle={{factor: 0}} style={{factor: spring(this.state.roundbutton_factor, [600, 12]) }}>
                                {({factor}) => (
                                  <Link to={"contactus"} spy={true} smooth={true} duration={500}>
                                    <CK.Button style={this.fadeIn(factor)} className="roundbutton">CONTACT US</CK.Button>
                                  </Link>
                                )}
                              </Motion>
                          )
                      }
                    </CK.View>
                  </CK.View>
                  <CK.View className="mask_right" style={{top: (this.state.parallax_y) + `px`}}>
                    <Motion defaultStyle={{factor: 0}} style={{factor: spring(this.state.gears_factor) }}>
                      {({factor}) => (
                        <CK.View style={this.fadeInFromBottom(factor, 450, 350, {})} className="gear_1" ></CK.View>
                      )}
                    </Motion>
                  </CK.View>
                  <CK.View className="mask_right" style={{top: (this.state.parallax_y) + `px`}}>
                    <Motion defaultStyle={{factor: 0}} style={{factor: spring(this.state.gears_factor) }}>
                      {({factor}) => (
                          <CK.View style={this.fadeInFromBottom(factor, 450, 350, {})} className="gear_2" ></CK.View>
                      )}
                    </Motion>
                  </CK.View>
                  <CK.View className="mask_right" style={{top: (this.state.parallax_y) + `px`}}>
                    <Motion defaultStyle={{factor: 0}} style={{factor: spring(this.state.macbook_factor) }}>
                      {({factor}) => (
                          <CK.View style={this.fadeInFromRight(factor, 300, 500, { top: `150px`, width: `600px` })}>
                            <CK.View className="shadow_bg"></CK.View>
                            <img src="./assets/images/eport_macbook_1.png" style={{width: `100%`, zIndex: 1000, position: `relative`}} />
                          </CK.View>
                      )}
                    </Motion>
                  </CK.View>
                  <CK.View className="mask_right" style={{top: (this.state.parallax_y) + `px`}}>
                    <Motion defaultStyle={{factor: 0}} style={{factor: spring(this.state.iphone_factor) }}>
                      {({factor}) => (
                          <CK.View style={this.fadeInFromBottom(factor, 450, 350, { left: `40px`, width: `400px` })}>
                            <CK.View className="shadow_bg"></CK.View>
                            <img src="./assets/images/vanport_ios_1.png" style={{width: `100%`, zIndex: 1000, position: `relative`}} />
                          </CK.View>
                      )}
                    </Motion>
                  </CK.View>
                </CK.View>
            )}
          </Motion>
        </CK.View>
    );
  }
}

