import React from 'react'
import ReactDOM from 'react-dom'
import Slider from 'react-slick'
import Parallax from 'react-parallax'
import Waypoint from 'react-waypoint';
import AutoResponsive from 'autoresponsive-react'
import AppHeader from '../components/AppHeader.jsx'
import OverlayBox from '../components/OverlayBox.jsx'
import {Motion, spring} from 'react-motion';

export default class Companies extends React.Component{

  constructor(props) {
    super(props)
    this.state = {
      factor: 0
    }

    this.companies = [
      // {name: "accordhk", icon: "./assets/images/p/accordhk.png" },
      // {name: "apptech", icon: "./assets/images/p/apptech.png" },
      {name: "cp", icon: "./assets/images/p/cp.png" },
      {name: "chunwo", icon: "./assets/images/p/chunwo.jpg" },
      {name: "crocodile", icon: "./assets/images/p/crocodile.png" },
      {name: "culturecom", icon: "./assets/images/p/culturecom.png" },
      {name: "d2bros", icon: "./assets/images/p/d2bros.png" },
      // {name: "devstudio", icon: "./assets/images/p/devstudio.jpg" },
      // {name: "documentonready", icon: "./assets/images/p/documentonready.png" },
      {name: "hkstp", icon: "./assets/images/p/hkstp.png" },
      {name: "imusictech", icon: "./assets/images/p/imusictech.jpg" },
      // {name: "kilocraft", icon: "./assets/images/p/kilocraft.png" },
      {name: "kodak", icon: "./assets/images/p/kodak.png" },
      {name: "uacinema", icon: "./assets/images/p/uac.png" },
      {name: "hktdc", icon: "./assets/images/p/hktdc.png" },
      {name: "prudential", icon: "./assets/images/p/pru.png" },
      {name: "philips", icon: "./assets/images/p/philips.png" },
      {name: "linka", icon: "./assets/images/p/linka.png" },
      {name: "4d", icon: "./assets/images/p/4d.png" },
      // {name: "livingtab", icon: "./assets/images/p/livingtab.png" },
      // {name: "mt", icon: "./assets/images/p/mt.png" },
      {name: "mtr", icon: "./assets/images/p/mtr.png" },
      {name: "sino", icon: "./assets/images/p/sino.png" },
      {name: "galaxy", icon: "./assets/images/p/galaxy.png" },
      {name: "pccwsoln", icon: "./assets/images/p/pccwsoln.png" },
      {name: "phd", icon: "./assets/images/p/phd.png" },
      {name: "sohomobhd", icon: "./assets/images/p/sohomobhd.jpg" },
      {name: "spikesasia", icon: "./assets/images/p/spikesasia.png" },
      {name: "ucan", icon: "./assets/images/p/ucan.png" },
      // {name: "von", icon: "./assets/images/p/von.png" },
      // {name: "wolvensheep", icon: "./assets/images/p/wolvensheep.jpg" },
    ]
  }


  componentDidMount() {

    window.addEventListener('scroll', this.handleScroll.bind(this));
  }

  cumulativeOffset(element) {
    var top = 0, left = 0;
    do {
      top += element.offsetTop  || 0;
      left += element.offsetLeft || 0;
      element = element.offsetParent;
    } while(element);

    return {
      top: top,
      left: left
    };
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll.bind(this));
  }

  handleScroll(e) {
    if (!this.is_appeared) {
      var top = this.cumulativeOffset(this.refs.waypoint).top;
      var height = this.refs.waypoint.offsetHeight;

      let body = $(document);
      let scrollTop = body.scrollTop();
      let itemTranslate = scrollTop / 2;

      window.h = this.refs.waypoint;

      if (top - window.screen.height < scrollTop && top + height > scrollTop) {
        this.is_appeared = true;
        this.onEnter();
        window.removeEventListener('scroll', this.handleScroll.bind(this));
      }
    }
  }


  onEnter() {
    setTimeout(() => {this.setState({factor: 1});}, 200);
  }



  fadeInFromTop(factor) {
    return {
      position: `relative`,
      top: `${-50 + (50 * factor)}px`,
      opacity: `${0 + (1 * factor)}`,
    }
  }


  render() {

    return (
        <CK.View className="companies">
          <div ref="waypoint" style={{height: `100%`, position: `absolute`}}></div>
          <CK.View className="" ref="container">

            <Motion defaultStyle={{factor: 0}} style={{factor: spring(this.state.factor) }}>
              {({factor}) => (
                  <div style={this.fadeInFromTop(factor)}>
                    <CK.View className="wrapper">
                      {
                          this.companies.map((company) => {
                                return (
                                    <CK.View className="icon" style={company.style ? company.style : {}}>
                                      <img src={company.icon} style={{height: `100%`}}/>
                                    </CK.View>
                                )
                              }
                          )
                      }
                    </CK.View>
                  </div>
              )}
            </Motion>

          </CK.View>
        </CK.View>
    );
  }
}

