import React from 'react'

export default class AppFooter extends React.Component{

  constructor(props) {
    super(props)
  }

  componentDidMount() {

  }


  componentWillUnmount() {

  }


  render() {
    return (
        <CK.View className="footer">
          <CK.View style={{backgroundColor: `#333333`, height: `140px`, top: `30px`, zIndex: 5}} className="skew_background_front" />
          <CK.View className="skew_content" style={{zIndex: 1000, position: `relative`}}>
            &copy; 2016-{new Date().getFullYear()} Vanport Software Consultancy Limited
          </CK.View>
        </CK.View>
    )
  }
}
