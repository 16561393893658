import React from 'react'
import ReactDOM from 'react-dom'
import Slider from 'react-slick'
import Parallax from 'react-parallax'
import Waypoint from 'react-waypoint';
import AutoResponsive from 'autoresponsive-react'
import AppHeader from '../components/AppHeader.jsx'
import OverlayBox from '../components/OverlayBox.jsx'
import {Motion, spring} from 'react-motion';
import Jumbotron from './IndexPageJumbotron.jsx'
import WhatWeDo from './IndexPageWhatWeDo.jsx'
import OurPortfolio from './IndexPageOurPortfolio.jsx'
import Companies from './IndexPageCompanies.jsx'
import ContactUs from './IndexPageContactUs.jsx'
import AppFooter from '../components/AppFooter.jsx'

var Scroll  = require('react-scroll');

var Link    = Scroll.Link;
var Element = Scroll.Element;
var Events  = Scroll.Events;
var scroll  = Scroll.animateScroll;

export default class IndexPage extends React.Component{

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    UserStorageActions.initStorage.defer();
  }

  render() {
    return (
        <CK.View>
          <AppHeader theme="BLACK"/>
          <AppHeader theme="WHITE"/>

          <Element name="jumbotron" style={{width: `100%`, position: `relative`}}>
            <Jumbotron />
          </Element>

          <Element name="whatwedo" style={{width: `100%`, position: `relative`}}>
            <WhatWeDo />
          </Element>

          <Element name="portfolio" style={{width: `100%`, position: `relative`}}>
            <OurPortfolio />
          </Element>

          <Companies />

          <Element name="contactus" style={{width: `100%`, position: `relative`}}>
            <ContactUs />
          </Element>

          <AppFooter />
        </CK.View>
    );
  }
}

