import React from 'react'
import ReactDOM from 'react-dom'
import Slider from 'react-slick'
import Parallax from 'react-parallax'
import Waypoint from 'react-waypoint';
import AutoResponsive from 'autoresponsive-react'
import AppHeader from '../components/AppHeader.jsx'
import OverlayBox from '../components/OverlayBox.jsx'
import {Motion, spring} from 'react-motion';

export default class ContactUs extends React.Component{

  constructor(props) {
    super(props)
    this.state = {
      prefix_factor: 0,
      suffix_factor: 0,
      subcaption_factor: 0,
      slider_factor: 0,
    }
  }

  onEnter() {
    setTimeout(() => {this.setState({prefix_factor: 1});}, 0);
    setTimeout(() => {this.setState({suffix_factor: 1});}, 200);
    setTimeout(() => {this.setState({subcaption_factor: 1});}, 400);
    setTimeout(() => {this.setState({slider_factor: 1});}, 600);
  }


  fadeIn(factor) {
    return {
      position: `relative`,
      opacity: `${0 + (1 * factor)}`,
    }
  }

  fadeInFromLeft(factor) {
    return {
      position: `relative`,
      left: `${-50 + (50 * factor)}px`,
      opacity: `${0 + (1 * factor)}`,
    }
  }

  fadeInFromTop(factor) {
    return {
      position: `relative`,
      top: `${-50 + (50 * factor)}px`,
      opacity: `${0 + (1 * factor)}`,
    }
  }


  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this));

    $("#form_contact_us").submit(function(e) {
      event.preventDefault();
      $.post( "https://ap.www.vanportdev.com:10018/api/pg/vanport_send_email", $(e.target).serialize())
      .done(function(data) {
        alert("Thanks for your enquiry! Our team will contact you soon.");
      })
    });
  }

  cumulativeOffset(element) {
    var top = 0, left = 0;
    do {
      top += element.offsetTop  || 0;
      left += element.offsetLeft || 0;
      element = element.offsetParent;
    } while(element);

    return {
      top: top,
      left: left
    };
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll.bind(this));
  }

  handleScroll(e) {
    if (!this.is_appeared) {
      var top = this.cumulativeOffset(this.refs.waypoint).top;
      var height = this.refs.waypoint.offsetHeight;

      let body = $(document);
      let scrollTop = body.scrollTop();
      let itemTranslate = scrollTop / 2;

      window.h = this.refs.waypoint;

      if (top - window.screen.height < scrollTop && top + height > scrollTop) {
        this.is_appeared = true;
        this.onEnter();
        window.removeEventListener('scroll', this.handleScroll.bind(this));
      }
    }
  }


  render() {

    return (

        <CK.View className="section contactus">
          <div ref="waypoint" style={{height: `100%`, position: `absolute`}}></div>
          <CK.View style={{backgroundColor: `#979797`}} className="skew_background_back" />
          <CK.View style={{backgroundSize: `100% auto`, backgroundImage: `url(./assets/images/contact_us_bg.jpg)`}} className="skew_background_front" />
          <CK.View className="skew_content">
            <CK.View className="wrapper">
              <CK.View className="caption">
                <Motion defaultStyle={{factor: 0}} style={{factor: spring(this.state.prefix_factor, [600, 20]) }}>
                  {({factor}) => (
                      <span style={this.fadeInFromLeft(factor)} className="prefix">CONTACT </span>
                  )}
                </Motion>
                <Motion defaultStyle={{factor: 0}} style={{factor: spring(this.state.suffix_factor, [600, 20]) }}>
                  {({factor}) => (
                      <span style={this.fadeInFromTop(factor)} className="suffix">US</span>
                  )}
                </Motion>
              </CK.View>
              <Motion defaultStyle={{factor: 0}} style={{factor: spring(this.state.subcaption_factor, [600, 20]) }}>
                {({factor}) => (
                    <CK.View className="division">&nbsp;</CK.View>
                )}
              </Motion>
              <Motion defaultStyle={{factor: 0}} style={{factor: spring(this.state.slider_factor) }}>
                {({factor}) => (
                    <CK.View style={this.fadeInFromLeft(factor)} className="subcaption">
                      <CK.View className="row">
                        <CK.View className="row">
                          <img src="./assets/images/icon_tel.png" />
                          <CK.View>852-51183837</CK.View>
                        </CK.View>
                        <CK.View className="row">
                          <img src="./assets/images/icon_email.png" />
                          <CK.View>vanson@vanportdev.com</CK.View>
                        </CK.View>
                      </CK.View>

                      <CK.View>
                        <form id="form_contact_us" method="post" style={{marginTop: `36px`, marginBottom: `36px`}}>
                          <CK.View className="field_row">
                            <img src="./assets/images/icon_name.png" className="icon" />
                            <input ref="name" type="text" name="name" className="suffix" placeholder="Name" />
                          </CK.View>
                          <CK.View className="field_row">
                            <img src="./assets/images/icon_email.png" className="icon" />
                            <input ref="email" type="text" name="email" className="suffix" placeholder="Email" />
                          </CK.View>
                          <CK.View className="field_row">
                            <img src="./assets/images/icon_email.png" className="icon" />
                            <input ref="phone" type="text" name="phone" className="suffix" placeholder="Phone" />
                          </CK.View>
                          <CK.View className="field_row">
                            <input ref="message" type="text" name="message" className="suffix" placeholder="Message" />
                          </CK.View>
                          <CK.Button type="submit" style={this.fadeIn(factor)} className="roundbutton">SEND</CK.Button>
                        </form>
                      </CK.View>

                    </CK.View>
                )}
              </Motion>
            </CK.View>
          </CK.View>
        </CK.View>
    );
  }
}




