import React from 'react'


export default class OverlayBox extends React.Component{

  constructor(props) {
    super(props)
    this.state = {
      show : false,
    }
  }

  show() {
    this.setState({
      show : true,
    })
  }

  hide() {
    this.setState({
      show : false,
    })
  }

  componentDidMount() {

  }

  render() {

    return (
        <div
            onClick={this.props.onClickHandler}
            onMouseEnter={this.show.bind(this)}
            onMouseLeave={this.hide.bind(this)}
            className="overlaybox"
            style={this.props.style}>
          <div className="overlay" style={this.props.overlayStyle}>
            <div className="overlay_bg" />
            <div className="info">
              <div className="title">
                {this.props.caption}
              </div>
              <span className="line">&nbsp;</span>
              <div className="desc">
                {this.props.subcaption}
              </div>
            </div>
          </div>
          <img style={{
            backgroundImage: "url("+this.props.image+")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }} />
        </div>
    );
  }
}

var styles = {};