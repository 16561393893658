class UserStorageActions {
  setObjectForKey(key, obj) {
    this.dispatch({
      key: key,
      obj: obj,
    });
  }
  resetKey(key) {
    this.dispatch({
      key: key,
    });
  }
  initStorage() {
    this.dispatch();
  }
  synchronize() {
    this.dispatch();
  }
}

module.exports = CK.Alt.createActions(UserStorageActions);