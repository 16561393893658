import React from 'react'
import ReactDOM from 'react-dom'
import Slider from 'react-slick'
import Parallax from 'react-parallax'
import Waypoint from 'react-waypoint';
import AutoResponsive from 'autoresponsive-react'
import AppHeader from '../components/AppHeader.jsx'
import OverlayBox from '../components/OverlayBox.jsx'
import {Motion, spring} from 'react-motion';

export default class OurPortfolio extends React.Component{

  constructor(props) {
    super(props)
    this.state = {
      portfolio_prefix_factor: 0,
      portfolio_suffix_factor: 0,
      portfolio_subcaption_factor: 0,
      portfolio_container_factor: 0,
      "category_name" : 'All'
    }
  }

  onEnter() {
    setTimeout(() => {this.setState({portfolio_prefix_factor: 1});}, 200);
    setTimeout(() => {this.setState({portfolio_suffix_factor: 1});}, 400);
    setTimeout(() => {this.setState({portfolio_container_factor: 1});}, 600);
  }


  fadeInFromLeft(factor) {
    return {
      position: `relative`,
      left: `${-50 + (50 * factor)}px`,
      opacity: `${0 + (1 * factor)}`,
    }
  }

  fadeInFromTop(factor) {
    return {
      position: `relative`,
      top: `${-50 + (50 * factor)}px`,
      opacity: `${0 + (1 * factor)}`,
    }
  }


  componentDidMount() {
    this.setState({
      containerWidth: this.getContainerWidth()
    });
    window.addEventListener('resize', () => {
      this.setState({
        containerWidth: this.getContainerWidth()
      });
    }, false);

    window.addEventListener('scroll', this.handleScroll.bind(this));
  }

  cumulativeOffset(element) {
    var top = 0, left = 0;
    do {
      top += element.offsetTop  || 0;
      left += element.offsetLeft || 0;
      element = element.offsetParent;
    } while(element);

    return {
      top: top,
      left: left
    };
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll.bind(this));
  }

  handleScroll(e) {
    if (!this.is_appeared) {
      var top = this.cumulativeOffset(this.refs.waypoint).top;
      var height = this.refs.waypoint.offsetHeight;

      let body = $(document);
      let scrollTop = body.scrollTop();
      let itemTranslate = scrollTop / 2;

      window.h = this.refs.waypoint;
      if (top - window.screen.height < scrollTop && top + height > scrollTop) {
        this.is_appeared = true;
        this.onEnter();
        window.removeEventListener('scroll', this.handleScroll.bind(this));
      }
    }
  }

  getContainerWidth() {
    return ReactDOM.findDOMNode(this.refs.container).clientWidth;
  }

  getAutoResponsiveProps() {
    return {
      itemMargin: 0,
      columnWidth: 0,
      containerWidth: this.state.containerWidth || 0,
      itemClassName: 'item',
      gridWidth: 1,
      transitionDuration: '.5',
      transitionTimingFunction: 'easeIn',
    };
  }

  render() {

    var categories = [
      {name: 'All', title: 'All'},
      {name: 'Apps', title: 'Apps'},
      {name: 'Systems', title: 'Systems'},
      {name: 'Games', title: 'Games'},
    ];

    var items = [
      {category_name: 'Games', caption: 'Move It! 齊動樂！有獎遊戲', subcaption: 'MTR Corporation Ltd.', image: './assets/images/3d/cpmtrminigames.jpg'},
      {category_name: 'Systems', caption: 'youCodia Teachers Console System', subcaption: 'YGN 青年全球網絡', image: './assets/images/3d/youcodia.jpg'},
      {category_name: 'Systems', caption: 'Philips (HK) Sales Management System', subcaption: 'Philips', image: './assets/images/3d/philips.jpg'},
      {category_name: 'Systems', caption: 'Infinitum Visum for Smart Homes', subcaption: 'PCCW Solutions Ltd.', image: './assets/images/3d/visum.jpg'},
      {category_name: 'Apps', caption: 'HKSTP', subcaption: 'Hong Kong Science & Technology Park Ltd.', image: './assets/images/3d/hkstp.jpg'},
      {category_name: 'Systems', caption: '2-way AC SMART CONTROLLER Solution', subcaption: 'D2Bros Ltd.', image: './assets/images/3d/d2bros.jpg'},
      {category_name: 'Apps', caption: 'Vogue Laundry App', subcaption: 'Vogue Laundry Ltd.', image: './assets/images/3d/vogue.jpg'},
      {category_name: 'Apps', caption: 'Barmap', subcaption: 'Barmap Ltd.', image: './assets/images/3d/barmap.jpg'},
      {category_name: 'Apps', caption: '易換錢 SmartRate', subcaption: 'Smart Rate Ltd.', image: './assets/images/3d/1.jpg'},
      {category_name: 'Systems', caption: 'Kiosk Display System', subcaption: 'Uniqlo (HK) Ltd.', image: './assets/images/3d/uniqlo2.jpg'},
      {category_name: 'Apps', caption: 'T-Factory (茶木,宮崎,漁牧,東京築地,點心瑪莉)', subcaption: '', image: './assets/images/3d/21.jpg'},
      {category_name: 'Apps', caption: 'Aimpact Stocks & Social Platform', subcaption: 'Aimpact Ltd.', image: './assets/images/3d/10.jpg'},
      {category_name: 'Apps', caption: '建築圖則管理 DWMS', subcaption: 'Chun Wo Building Construction Ltd.', image: './assets/images/3d/8.jpg'},
      {category_name: 'Apps', caption: 'IronOre B2B Platform', subcaption: 'IronOre Bicycle Ltd.', image: './assets/images/3d/17.jpg'},
      {category_name: 'Games', caption: 'Dreamcheeky Sound System', subcaption: 'Dreamcheeky Ltd.', image: './assets/images/3d/20.jpg'},
      {category_name: 'Apps', caption: 'Stayplease Hotel Management System', subcaption: 'Galaxy', image: './assets/images/3d/9.jpg'},
      {category_name: 'Apps', caption: 'Seashell Underwater Camera', subcaption: 'Zear Ltd.', image: './assets/images/3d/18.jpg'},
      {category_name: 'Apps', caption: '私房易管理平台', subcaption: 'Private Kitchen Delivery O2O', image: './assets/images/3d/15.jpg'},
      {category_name: 'Games', caption: 'Mobile Violin', subcaption: '王梓軒 J-Wo', image: './assets/images/3d/7.jpg'},
      {category_name: 'Games', caption: '周杰倫 Jay Chou\'s UCanTap', subcaption: 'UCan Ltd.', image: './assets/images/3d/3.jpg'},
      {category_name: 'Apps', caption: 'Mak Ling Ling App', subcaption: '', image: './assets/images/3d/13.jpg'},
      {category_name: 'Apps', caption: 'Social Enterprise Summit 2015', subcaption: '', image: './assets/images/3d/16.jpg'},
      {category_name: 'Games', caption: '2utor智力大比拼比賽評核系統', subcaption: '2utor', image: './assets/images/3d/6.jpg'},
      {category_name: 'Apps', caption: 'Spikes 2014', subcaption: 'Spikes Asia', image: './assets/images/3d/11.jpg'},
      {category_name: 'Games', caption: 'Bum Bum Babies', subcaption: 'Little Big Studio', image: './assets/images/3d/4.jpg'},
      {category_name: 'Apps', caption: 'Stock Notifier', subcaption: '', image: './assets/images/3d/19.jpg'},
      {category_name: 'Games', caption: 'Drum Hysteria', subcaption: 'Dreamcheeky Ltd.', image: './assets/images/3d/2.jpg'},
      {category_name: 'Apps', caption: 'PURiL Infrared', subcaption: 'Tecuno Ltd.', image: './assets/images/3d/14.jpg'},
    ];

    var filtered = items.filter((item) => {
      if (this.state.category_name == 'All') { return true }
      if (this.state.category_name == item.category_name) { return true }
      return true;
    });


    return (

        <CK.View className="ourportfolio">
          <div ref="waypoint" style={{height: `100%`, position: `absolute`}}></div>
          <CK.View className="caption">
            <Motion defaultStyle={{factor: 0}} style={{factor: spring(this.state.portfolio_prefix_factor, [600, 20]) }}>
              {({factor}) => (
                  <span style={this.fadeInFromLeft(factor)} className="prefix">Our </span>
              )}
            </Motion>
            <Motion defaultStyle={{factor: 0}} style={{factor: spring(this.state.portfolio_suffix_factor, [600, 20]) }}>
              {({factor}) => (
                  <span style={this.fadeInFromTop(factor)} className="suffix">Portfolio</span>
              )}
            </Motion>
          </CK.View>


          <CK.View className="" ref="container">

            <Motion defaultStyle={{factor: 0}} style={{factor: spring(this.state.portfolio_container_factor) }}>
              {({factor}) => (
                  <div style={this.fadeInFromTop(factor)}>
                    {
                      categories.map((cat, i) => {
                        var className = "roundbutton orange_theme small " + (cat.name == this.state.category_name ? "highlight" : "");
                        return (
                            <CK.Button className={className} style={{margin: "24px 12px"}} onClick={() => this.setState({category_name : cat.name})}>{cat.title}</CK.Button>
                        )
                      })
                    }
                    <AutoResponsive {...this.getAutoResponsiveProps()}>
                      {
                        filtered.map((item, i) => {
                          var bodyWidth = $('body').width();
                          var columns = Math.floor(bodyWidth / 320) + 1;
                          var width = $('body').width() / columns;
                          var height = (width * 2) / 3;
                          var style = {
                            width: 0,
                            height: 0,
                            paddingBottom: 0,
                            paddingRight: 0,
                          };
                          var styleh = {
                            width: width,
                            height: height,
                            paddingBottom: 0,
                            paddingRight: 0,
                          };

                          var style2 = {
                            top: 0,
                          };

                          if (!item.force_false) {
                            if (this.state.category_name == 'All') { style = styleh }
                            if (this.state.category_name == item.category_name) { style = styleh; }
                          }

                          return (
                              <OverlayBox {...item} style={style} overlayStyle={style2} />
                          )
                        })
                      }
                    </AutoResponsive>
                  </div>
              )}
            </Motion>

          </CK.View>
        </CK.View>
    );
  }
}

